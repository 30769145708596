import React, { Component } from 'react';
import './style.css';
import documentation from './documentation.js';
import $ from 'jquery'; 

class Documentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentation
    }

    this.isInViewport = this.isInViewport.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  async componentDidMount() {
    let loggedin = await localStorage.getItem('loggedin');

    if(!loggedin) {window.location.replace('/')}

    $('.main_panel')[0].addEventListener('scroll', this.handleScroll);

    this.computeSelectedLink();
  }

  async componentDidUpdate() {
    this.computeSelectedLink()
  }

  computeSelectedLink() {
    let hash = window.location.hash;
    let documentation = this.state.documentation;

   

    $(`a`).removeClass("hilighted");
    if(!hash) {
      $(`a[href="#${documentation[0].subsections[0].id}"`).addClass("hilighted");
      $(`a[href="#${documentation[0].subsections[0].id}"`)[0].scrollIntoView(false);
    } else {

      let H3s = $('h3');

      let element, id;
  
      for(var i = 0; i < H3s.length; i++) {
        element = H3s[i];
        id = $(element).attr('id');
        console.log('id', id)
        console.log('hash', hash)
  
        if(`#${id}` === hash) {
          console.log('id', id)
          $(`a[href="#${id}"`).addClass("hilighted");
          $(`a[href="#${id}"`)[0].scrollIntoView(false);
        }
      }
    }
  }

  isInViewport(element) {
    let offset = $(element).offset();
    //console.log(element, offset)

    return offset.top < 200 && offset.top > 100;
  }

  handleScroll() {
    // find out which h3 is in view

    let H3s = $('h3');
    let element, id;

    for(var i = 0; i < H3s.length; i++) {
      element = H3s[i];
      id = $(element).attr('id');

      if(id && this.isInViewport(element)) {
        console.log('element', element)
        
        $(`a`).removeClass("hilighted");
        $(`a[href="#${id}"`).addClass("hilighted");
        $(`a[href="#${id}"`)[0].scrollIntoView(false);
      }
    }
  }

  goTo(name) {
    window.location.replace(`#${name}`)
  }

  copyLink(link) {
    /* document.addEventListener('copy', async function(e) {
      var data = window.getSelection().toString();

      if(data[0] === '"') {
        data = data.substring(1);
      }

      if(data[data.length - 1] === '"') {
        data = data.substring(0, data.length - 1);
      }

      e.clipboardData.setData('text/plain', data);
      e.preventDefault();
    }); */
    let url = `${window.location.origin}/documentation/#${link}`;
    navigator.clipboard.writeText(url);
    window.location.replace(url)
  }

  copyThis(text) {
    navigator.clipboard.writeText(text);
  }

  openMenu(id) {
    $('.left_panel').toggleClass('open');
    $('#mobile_menu_caret').toggleClass('fa-caret-up');

    $(`a`).removeClass("hilighted");
    $(`a[href="#${id}"`).addClass("hilighted");
    $(`a[href="#${id}"`)[0].scrollIntoView(false);
  }

  drawSubMenu(section) {
    let { id, name } = section;
    return (
      <>
          <a onClick={()=>this.openMenu(id)} href={`#${id}`} className="link">{name}</a>
      </>
    )
  }

  drawMenu(section) {
    let { id, name, subsections } = section;
    return (
      <>
        <div onClick={()=>{this.goTo(id)}} className="title">{name}</div>
        <div className="sub_menu">
          {subsections ? subsections.map(a => {return this.drawSubMenu(a)}) : null}
        </div>
      </>
    )
  }

  drawSubscection(section) {
    let { id, name, text } = section;
    return (
      <>
        <h3 id={id}>{name} <i onClick={()=>{this.copyLink(id)}} className="fa fa-link"></i></h3>
        <div className="text">
          {text}
        </div>
      </>
    )
  }

  drawContent(section) {
    let { id, name, subsections } = section;
    let id_first_section = subsections[0].id
    return (          <div id={id}>
      <h1 id={id}>{name} <i onClick={()=>{this.copyLink(id_first_section)}} className="fa fa-link"></i></h1>
      {subsections.map(a=>{return this.drawSubscection(a)})}      
    </div>)
  }

  render() {
    return (
      <div className="info">
        <div className="doc_header">
        <div onClick={this.openMenu} className="mobile_bars"><span>menu</span> <i id="mobile_menu_caret" className="fa fa-caret-down"></i></div>
          {/* <HomeHeader match={this.props.match}/> */}
        </div>

        <div className="documentation">
          <div className='left_panel'>
            <div className="main_nav_content">
              {documentation.map(a => {return this.drawMenu(a)})}
            </div>
          </div>
          <div  className="main_panel">
            {documentation.map(a => {return this.drawContent(a)})}
          </div>
        </div>


      </div>
    );
  }
}

export default Documentation;
