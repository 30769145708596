import React, { Component } from 'react';
import './App.css';
import { Navigate, BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home, Documentation } from './pages';

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mounted: false
    }
  }

  async componentDidMount() {
    let token = await localStorage.getItem('token');

    this.setState({
      isLoggedIn: !!token,
      mounted: true
    })
  }

  render() {
    let isLoggedIn = this.state.isLoggedIn;
    let mounted = this.state.mounted;

    return (
    <div className="App">
      <BrowserRouter>      
            {mounted ?
                <div className="main_content">
                    <div className="content">
                      {isLoggedIn ? (
                        <Routes>
                          <Route key="1" path="/" element={<Home />} />
                          <Route key="8" path="/documentation" element={<Documentation/>} />
                          <Route key="6"  element={() => <Navigate to="/" />}  />
                        </Routes>
                        ) : (
                          <Routes>
                            <Route key="1" path="/" element={<Home />} />
                            <Route key="8" path="/documentation" element={<Documentation/>} />
                            <Route key="6" element={() => <Navigate to="/" />}  />
                          </Routes>
                        )}
                    
                    </div>
                
                </div>
              : null}
          </BrowserRouter>
    </div>
  );
}
}

export default App;
