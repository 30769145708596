const documentation = [
    {
        name: "Section 1",
        id: "section_1_id",
        subsections: [
            {
                name: "Section One",
                id: "section_1_id_1",
                text: <div>sLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor vehicula convallis. Nam ac leo ac dolor bibendum congue eu non tortor. Pellentesque vitae augue risus. Quisque aliquet interdum velit, nec semper ex. Phasellus est sapien, scelerisque id purus ac, elementum viverra libero. Nulla non tempor ligula. Vivamus ligula tortor, auctor posuere enim et, cursus volutpat quam. Nam mi odio, gravida non viverra et, posuere non odio. Pellentesque condimentum maximus tempor. In a arcu in enim dictum molestie vitae sed diam. Duis lobortis, purus eu fermentum tempor, diam lacus fermentum enim, ut blandit nisl magna vel nisi.
                    <br/><br/>
                Fusce metus massa, tincidunt in ornare non, sagittis a purus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce vel mauris metus. Proin congue interdum velit, imperdiet pharetra ligula accumsan eget. Curabitur vulputate orci ac tellus dictum dapibus et at justo. Aenean sit amet vulputate mi. Maecenas viverra vehicula nisl, nec ultricies ipsum commodo eu. Phasellus in erat nisl. Vivamus eu eros odio. Praesent eu facilisis urna, vel bibendum turpis. Aenean bibendum arcu quis scelerisque pretium.
                <br/><br/>
                Praesent euismod eget ligula ut sollicitudin. Aenean arcu lectus, consectetur vel nulla non, lacinia ultrices magna. Duis pellentesque mauris orci, eget consequat tortor ullamcorper sed. Integer ac laoreet lectus. Phasellus viverra venenatis nunc, quis ullamcorper lacus consequat ac. Curabitur iaculis commodo urna ac lacinia. Nam porttitor sapien eget nulla venenatis, non euismod eros efficitur. Duis vulputate lacinia tortor, vel tempus urna cursus vitae. Donec sollicitudin scelerisque nulla. Integer at augue lectus. Sed mattis tellus eget felis ornare pulvinar. Cras et vehicula urna.
                <br/><br/>
                Donec in tortor augue. Cras dapibus massa felis, ut gravida turpis placerat sit amet. Pellentesque metus elit, ultrices imperdiet convallis in, lobortis eget dolor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque non viverra ligula, vel consectetur lacus. Quisque eget erat vel odio accumsan mollis. Nulla libero lorem, pretium sodales orci sed, sagittis interdum magna. Morbi id ultricies magna. Praesent luctus risus est, eu porttitor risus efficitur nec. Praesent scelerisque dui sollicitudin magna convallis semper.</div>
            },
            {
                name: "Section Two",
                id: "section_1_id_2",
                text: <div>sLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor vehicula convallis. Nam ac leo ac dolor bibendum congue eu non tortor. Pellentesque vitae augue risus. Quisque aliquet interdum velit, nec semper ex. Phasellus est sapien, scelerisque id purus ac, elementum viverra libero. Nulla non tempor ligula. Vivamus ligula tortor, auctor posuere enim et, cursus volutpat quam. Nam mi odio, gravida non viverra et, posuere non odio. Pellentesque condimentum maximus tempor. In a arcu in enim dictum molestie vitae sed diam. Duis lobortis, purus eu fermentum tempor, diam lacus fermentum enim, ut blandit nisl magna vel nisi.
                <br/><br/>
            Fusce metus massa, tincidunt in ornare non, sagittis a purus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce vel mauris metus. Proin congue interdum velit, imperdiet pharetra ligula accumsan eget. Curabitur vulputate orci ac tellus dictum dapibus et at justo. Aenean sit amet vulputate mi. Maecenas viverra vehicula nisl, nec ultricies ipsum commodo eu. Phasellus in erat nisl. Vivamus eu eros odio. Praesent eu facilisis urna, vel bibendum turpis. Aenean bibendum arcu quis scelerisque pretium.
            <br/><br/>
            Praesent euismod eget ligula ut sollicitudin. Aenean arcu lectus, consectetur vel nulla non, lacinia ultrices magna. Duis pellentesque mauris orci, eget consequat tortor ullamcorper sed. Integer ac laoreet lectus. Phasellus viverra venenatis nunc, quis ullamcorper lacus consequat ac. Curabitur iaculis commodo urna ac lacinia. Nam porttitor sapien eget nulla venenatis, non euismod eros efficitur. Duis vulputate lacinia tortor, vel tempus urna cursus vitae. Donec sollicitudin scelerisque nulla. Integer at augue lectus. Sed mattis tellus eget felis ornare pulvinar. Cras et vehicula urna.
            <br/><br/>
            Donec in tortor augue. Cras dapibus massa felis, ut gravida turpis placerat sit amet. Pellentesque metus elit, ultrices imperdiet convallis in, lobortis eget dolor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque non viverra ligula, vel consectetur lacus. Quisque eget erat vel odio accumsan mollis. Nulla libero lorem, pretium sodales orci sed, sagittis interdum magna. Morbi id ultricies magna. Praesent luctus risus est, eu porttitor risus efficitur nec. Praesent scelerisque dui sollicitudin magna convallis semper.</div>
            }
        ]
    },
    {
        name: "Section 2",
        id: "section_2_id",
        subsections: [
            {
                name: "Section One",
                id: "section_2_id_1",
                text: <div>sLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor vehicula convallis. Nam ac leo ac dolor bibendum congue eu non tortor. Pellentesque vitae augue risus. Quisque aliquet interdum velit, nec semper ex. Phasellus est sapien, scelerisque id purus ac, elementum viverra libero. Nulla non tempor ligula. Vivamus ligula tortor, auctor posuere enim et, cursus volutpat quam. Nam mi odio, gravida non viverra et, posuere non odio. Pellentesque condimentum maximus tempor. In a arcu in enim dictum molestie vitae sed diam. Duis lobortis, purus eu fermentum tempor, diam lacus fermentum enim, ut blandit nisl magna vel nisi.
                <br/><br/>
            Fusce metus massa, tincidunt in ornare non, sagittis a purus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce vel mauris metus. Proin congue interdum velit, imperdiet pharetra ligula accumsan eget. Curabitur vulputate orci ac tellus dictum dapibus et at justo. Aenean sit amet vulputate mi. Maecenas viverra vehicula nisl, nec ultricies ipsum commodo eu. Phasellus in erat nisl. Vivamus eu eros odio. Praesent eu facilisis urna, vel bibendum turpis. Aenean bibendum arcu quis scelerisque pretium.
            <br/><br/>
            Praesent euismod eget ligula ut sollicitudin. Aenean arcu lectus, consectetur vel nulla non, lacinia ultrices magna. Duis pellentesque mauris orci, eget consequat tortor ullamcorper sed. Integer ac laoreet lectus. Phasellus viverra venenatis nunc, quis ullamcorper lacus consequat ac. Curabitur iaculis commodo urna ac lacinia. Nam porttitor sapien eget nulla venenatis, non euismod eros efficitur. Duis vulputate lacinia tortor, vel tempus urna cursus vitae. Donec sollicitudin scelerisque nulla. Integer at augue lectus. Sed mattis tellus eget felis ornare pulvinar. Cras et vehicula urna.
            <br/><br/>
            Donec in tortor augue. Cras dapibus massa felis, ut gravida turpis placerat sit amet. Pellentesque metus elit, ultrices imperdiet convallis in, lobortis eget dolor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque non viverra ligula, vel consectetur lacus. Quisque eget erat vel odio accumsan mollis. Nulla libero lorem, pretium sodales orci sed, sagittis interdum magna. Morbi id ultricies magna. Praesent luctus risus est, eu porttitor risus efficitur nec. Praesent scelerisque dui sollicitudin magna convallis semper.</div>
            },
            {
                name: "Section Two",
                id: "section_2_id_2",
                text: <div>sLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor vehicula convallis. Nam ac leo ac dolor bibendum congue eu non tortor. Pellentesque vitae augue risus. Quisque aliquet interdum velit, nec semper ex. Phasellus est sapien, scelerisque id purus ac, elementum viverra libero. Nulla non tempor ligula. Vivamus ligula tortor, auctor posuere enim et, cursus volutpat quam. Nam mi odio, gravida non viverra et, posuere non odio. Pellentesque condimentum maximus tempor. In a arcu in enim dictum molestie vitae sed diam. Duis lobortis, purus eu fermentum tempor, diam lacus fermentum enim, ut blandit nisl magna vel nisi.
                <br/><br/>
            Fusce metus massa, tincidunt in ornare non, sagittis a purus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce vel mauris metus. Proin congue interdum velit, imperdiet pharetra ligula accumsan eget. Curabitur vulputate orci ac tellus dictum dapibus et at justo. Aenean sit amet vulputate mi. Maecenas viverra vehicula nisl, nec ultricies ipsum commodo eu. Phasellus in erat nisl. Vivamus eu eros odio. Praesent eu facilisis urna, vel bibendum turpis. Aenean bibendum arcu quis scelerisque pretium.
            <br/><br/>
            Praesent euismod eget ligula ut sollicitudin. Aenean arcu lectus, consectetur vel nulla non, lacinia ultrices magna. Duis pellentesque mauris orci, eget consequat tortor ullamcorper sed. Integer ac laoreet lectus. Phasellus viverra venenatis nunc, quis ullamcorper lacus consequat ac. Curabitur iaculis commodo urna ac lacinia. Nam porttitor sapien eget nulla venenatis, non euismod eros efficitur. Duis vulputate lacinia tortor, vel tempus urna cursus vitae. Donec sollicitudin scelerisque nulla. Integer at augue lectus. Sed mattis tellus eget felis ornare pulvinar. Cras et vehicula urna.
            <br/><br/>
            Donec in tortor augue. Cras dapibus massa felis, ut gravida turpis placerat sit amet. Pellentesque metus elit, ultrices imperdiet convallis in, lobortis eget dolor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque non viverra ligula, vel consectetur lacus. Quisque eget erat vel odio accumsan mollis. Nulla libero lorem, pretium sodales orci sed, sagittis interdum magna. Morbi id ultricies magna. Praesent luctus risus est, eu porttitor risus efficitur nec. Praesent scelerisque dui sollicitudin magna convallis semper.</div>
            }
        ]
    }
];

export default documentation;