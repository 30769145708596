import React, { Component } from 'react';
import './style.css';

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mounted: false
    }

    this.login = this.login.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  async componentDidMount() {
    let token = await localStorage.getItem('token')

    this.setState({
      isLoggedIn: !!token,
      mounted: true
    })
  }

  async login() {
    let { username, password } = this.state;

    let url = 'https://data-dev.teletails.com/data/v4/api_login'
    let response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username, password})
    });

    let res = await response.json();

    console.log('res', res)

    if(res.success) {
      await localStorage.setItem('loggedin', 'true');
      window.location.replace('/documentation');
    } else {
      this.setState({
        error: res.data ? res.data.system_error : "Username or password incorrect."
      })
    }
  }

  updateValue(name, value) {
    this.setState({
      [name]: value
    })
  }

  render() {
    let error = this.state.error;

    return (
    <div className="Home">
              <h1>Login to TeleTails API Docs</h1>
      <div className='login_box'>
        <input onChange={(event)=>{this.updateValue('username', event.target.value)}} type="text" placeholder='username' />
        <input onChange={(event)=>{this.updateValue('password', event.target.value)}} type="password" placeholder='password' />
        <input type="button" value="Go" onClick={this.login} />
        <span style={{color: 'red'}}>{error ? error : null}</span>
      </div>
    </div>
  );
}
}

export default Home;
